
import * as cloudinaryRuntime$DK1GI6DIn6 from '/app/node_modules/@nuxt/image/dist/runtime/providers/cloudinary'
import * as ipxStaticRuntime$sABPAoem9X from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudinary']: { provider: cloudinaryRuntime$DK1GI6DIn6, defaults: {"baseURL":"https://res.cloudinary.com/dxkrez2fk/image/upload/"} },
  ['ipxStatic']: { provider: ipxStaticRuntime$sABPAoem9X, defaults: {} }
}
        